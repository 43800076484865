import {
  StaffMemberListItems,
  StaffMemberListItemsVariables,
} from '@/api/__generated__/QUERY_STAFF_MEMBER_LIST_ITEMS.codegen';
import { isDefined } from '@/api/utils';
import {
  StaffMemberDetails,
  StaffMemberDetailsVariables,
} from '@/api/__generated__/QUERY_STAFF_MEMBER_DETAILS.codegen';
import { ApolloCache } from '@apollo/client';
import { QUERY_STAFF_MEMBER_DETAILS } from '@/api/QUERY_STAFF_MEMBER_DETAILS';
import { QUERY_STAFF_MEMBER_LIST_ITEMS } from '@/api/QUERY_STAFF_MEMBER_LIST_ITEMS';
import { Account } from '@/api/rest/fetchAccount';

export const getEdgesWithCurrentMemberAsAdmin = (
  items: StaffMemberListItems,
  userId: number,
) =>
  items?.restaurant.users.edges?.filter(isDefined).map((edge) => {
    if (edge?.node.id === userId) {
      return {
        ...edge,
        isAdmin: true,
      };
    }
    return edge;
  }) || [];

export const updateCache = <
  T extends StaffMemberDetails | StaffMemberListItems | null,
>(
  query: T,
  userId: number,
) =>
  query?.restaurant.users
    ? {
        ...query,
        restaurant: {
          ...query?.restaurant,
          users: {
            ...query?.restaurant.users,
            edges: getEdgesWithCurrentMemberAsAdmin(query, userId),
          },
        },
      }
    : query;

export const updateStaffMemberListItemsCacheWithAdmin = (
  cache: ApolloCache<StaffMemberDetails>,
  restaurantId: number,
  userId: number,
) => {
  cache.updateQuery<StaffMemberDetails, StaffMemberDetailsVariables>(
    {
      query: QUERY_STAFF_MEMBER_DETAILS,
      variables: {
        ids: [userId],
        restaurantId,
      },
    },
    (query) => updateCache<StaffMemberDetails | null>(query, userId),
  );
  cache.updateQuery<StaffMemberListItems, StaffMemberListItemsVariables>(
    {
      query: QUERY_STAFF_MEMBER_DETAILS,
      variables: {
        restaurantId,
      },
    },
    (query) => updateCache<StaffMemberListItems | null>(query, userId),
  );
};

export const updateStaffMemberListItemsCacheWithRemovedMember = (
  cache: ApolloCache<StaffMemberListItems>,
  restaurantId: number,
  userId: number,
) => {
  cache.updateQuery<StaffMemberListItems, StaffMemberListItemsVariables>(
    {
      query: QUERY_STAFF_MEMBER_LIST_ITEMS,
      variables: {
        restaurantId,
      },
    },
    (query) =>
      query?.restaurant.users
        ? {
            ...query,
            restaurant: {
              ...query?.restaurant,
              users: {
                ...query?.restaurant.users,
                edges:
                  query?.restaurant.users.edges
                    ?.filter(isDefined)
                    .filter((edge) => edge?.node.id !== userId) || [],
              },
            },
          }
        : query,
  );
};

export const getUpdateStaffMemberOptions = (
  restaurantId: number,
  userId: number,
  updateFn: (
    cache: ApolloCache<StaffMemberListItems>,
    restaurantId: number,
    userId: number,
  ) => void,
) => ({
  variables: {
    input: {
      restaurantId,
      userId,
    },
  },
  update: (cache: ApolloCache<StaffMemberListItems>) => {
    updateFn(cache, restaurantId, userId);
  },
});

export const getStaffMembers = (
  items?: StaffMemberListItems,
  account?: Account,
) => {
  if (!account) {
    return [];
  }
  return (
    items?.restaurant.users.edges
      ?.filter(isDefined)
      .filter((edge) => edge.node.id !== account.id)
      .map((edge) => ({
        name: edge.node.name,
        isOwner: edge.isAdmin,
        id: edge.node.id,
      })) || []
  );
};
