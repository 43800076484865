import { gql } from '@apollo/client';
import { FRAGMENT_STAFF_MEMBER_LIST_ITEM_DATA } from '@/api/fragments/staff/listItem';

export const QUERY_STAFF_MEMBER_DETAILS = gql`
  ${FRAGMENT_STAFF_MEMBER_LIST_ITEM_DATA}
  query StaffMemberDetails($restaurantId: Int!, $ids: [Int!]) {
    restaurant(id: $restaurantId) {
      id
      users(ids: $ids) {
        edges {
          isAdmin
          node {
            ...StaffMemberListItemData
          }
        }
      }
    }
  }
`;
