import { FC, useCallback, useEffect, useRef } from 'react';
import { StaffEmpty, StaffHeader, StaffMembersList } from '@/components/Staff';
import { ContentLayout } from '@/components/ContentLayout';
import { useInvitationLink, useStaff } from '@/api/staff';
import { Appear } from '@/components/Appear';
import { useCurrentRestaurant } from '@/api/rest/useCurrentRestaurant';
import { useMyAccount } from '@/api/rest/fetchAccount';
import { EventAction, useStaffAnalytics } from '@/api/utils';
import { AddMemberDialog } from '@/containers/Staff/AddMemberDialog';
import { showErrorToast } from '../../lib/toast';

export type StaffContainerProps = {};

export const StaffContainer: FC<StaffContainerProps> = () => {
  const { members, loading } = useStaff();
  const { data: restaurant } = useCurrentRestaurant();
  const { data: account } = useMyAccount();
  const { generateInvitationLink, invitationLink } = useInvitationLink();
  const invitationAppear = useRef<Appear>(null);
  const {
    trackStaffListEvent,
    trackAddStaffMemberDialogEvent,
    handleAddMemberDialogScreenChange,
  } = useStaffAnalytics();
  const showInvitationDialog = useCallback(() => {
    invitationAppear.current?.show();
    trackStaffListEvent(EventAction.ADD_MEMBER_TAPPED);
  }, [trackStaffListEvent]);
  const handleSendInviteClick = useCallback(async () => {
    trackAddStaffMemberDialogEvent(EventAction.OK_TAPPED);
    return generateInvitationLink().catch((e) => {
      showErrorToast((e as Error).message);
      invitationAppear.current?.hide();
    });
  }, [generateInvitationLink, trackAddStaffMemberDialogEvent]);

  useEffect(() => {
    trackStaffListEvent(EventAction.VIEW);
  }, [trackStaffListEvent]);

  return (
    <ContentLayout
      size={members.length ? 'large' : 'small'}
      className="margin-top-4xl"
    >
      {!loading && restaurant && (
        <>
          <AddMemberDialog
            restaurantName={restaurant.restaurant_name}
            ref={invitationAppear}
            onSendInviteClick={handleSendInviteClick}
            invitationLink={invitationLink}
            onShow={() => trackAddStaffMemberDialogEvent(EventAction.VIEW)}
            onCancelClick={() =>
              trackAddStaffMemberDialogEvent(EventAction.CANCEL_TAPPED)
            }
            onScreenChange={handleAddMemberDialogScreenChange}
            onShareLink={() =>
              trackAddStaffMemberDialogEvent(EventAction.SHARE_LINK_TAPPED)
            }
          />
          {members.length ? (
            <>
              <StaffHeader
                onAddMemberClick={showInvitationDialog}
                name={account.name?.split(' ')[0]}
                restaurantName={restaurant.restaurant_name}
                className="margin-bottom-xxl"
              />
              <StaffMembersList
                members={members}
                onMemberClick={(member) =>
                  trackStaffListEvent(EventAction.CELL_TAPPED, {
                    staff_member_id: member.id,
                  })
                }
              />
            </>
          ) : (
            <StaffEmpty onInviteClick={showInvitationDialog} />
          )}
        </>
      )}
    </ContentLayout>
  );
};
