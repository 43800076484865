import { useCallback } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { MUTATION_ADD_STAFF_MEMBER } from '@/api/MUTATION_ADD_STAFF_MEMBER';
import {
  AddStaffMember,
  AddStaffMemberVariables,
} from '@/api/__generated__/MUTATION_ADD_STAFF_MEMBER.codegen';
import { useCurrentRestaurantId } from '@/store/useCurrentRestaurantId';
import { QUERY_STAFF_INVITATION_DETAILS } from '@/api/QUERY_STAFF_INVITATION_DETAILS';
import {
  StaffInvitationDetails,
  StaffInvitationDetailsVariables,
} from '@/api/__generated__/QUERY_STAFF_INVITATION_DETAILS.codegen';
import { MUTATION_ACCEPT_INVITATION_TOKEN } from '@/api/MUTATION_ACCEPT_INVITATION_TOKEN';
import {
  AcceptInvitationToken,
  AcceptInvitationTokenVariables,
} from '@/api/__generated__/MUTATION_ACCEPT_INVITATION_TOKEN.codegen';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '@/api/rest/QueryKeys';

export const getInvitationLink = (token: string) => {
  if (global.window) {
    const url = new URL(
      `${global.window.location.origin}/staff-invite/${token}`,
    );
    return url.toString();
  }
};

export const useAddStaffMember = () => {
  const restaurantId = useCurrentRestaurantId();
  const [mutate, { data, loading, error }] = useMutation<
    AddStaffMember,
    AddStaffMemberVariables
  >(MUTATION_ADD_STAFF_MEMBER);

  const generateInvitationLink = useCallback(
    () =>
      mutate({
        variables: {
          input: {
            restaurantId,
          },
        },
      }),
    [mutate, restaurantId],
  );
  const token = data?.restaurantGenerateInvitationToken?.invitationToken?.token;

  return {
    generateInvitationLink,
    invitationLink: token && getInvitationLink(token),
    loading,
    error,
  };
};

export const useStaffInvitationInfo = () => {
  const [query, { data, loading, error }] = useLazyQuery<
    StaffInvitationDetails,
    StaffInvitationDetailsVariables
  >(QUERY_STAFF_INVITATION_DETAILS);

  const getInvitationInfo = useCallback(
    async (token: string) =>
      query({
        variables: {
          token,
        },
      }),
    [query],
  );

  return {
    getInvitationInfo,
    invitationInfo: data?.restaurantInvitationToken,
    loading,
    error,
  };
};

export const useAcceptInvitation = () => {
  const [mutate, { data, loading, error }] = useMutation<
    AcceptInvitationToken,
    AcceptInvitationTokenVariables
  >(MUTATION_ACCEPT_INVITATION_TOKEN);
  const queryClient = useQueryClient();

  const acceptInvitation = useCallback(
    (token: string) =>
      mutate({
        variables: {
          input: {
            token,
          },
        },
        onCompleted: () => {
          queryClient.invalidateQueries(QueryKeys.MY_ACCOUNT);
        },
      }),
    [mutate, queryClient],
  );

  return {
    acceptInvitation,
    restaurant: data?.restaurantJoinByInvitationToken?.restaurant,
    loading,
    error,
  };
};

export const useInvitationLink = () => {
  const { generateInvitationLink, invitationLink } = useAddStaffMember();
  const { getInvitationInfo, invitationInfo } = useStaffInvitationInfo();
  const { acceptInvitation, restaurant } = useAcceptInvitation();

  return {
    invitationLink,
    generateInvitationLink,
    acceptInvitation,
    restaurant,
    invitationInfo,
    getInvitationInfo,
  };
};
