import { FC } from 'react';
import { StaffMembersListItem } from '@/components/Staff/StaffMembersListItem';
import { getNextListColorValueFn } from '@/components/ColorValues/ColorValues';
import styles from './StaffMembersList.module.scss';
import { StaffMember } from '@/api/staff';

export type StaffMembersListProps = {
  members: StaffMember[];
  onMemberClick?: (member: StaffMember) => void;
};

export const StaffMembersList: FC<StaffMembersListProps> = ({
  members,
  onMemberClick,
}) => {
  const getNextListColor = getNextListColorValueFn(2);

  return (
    <div className={styles.list}>
      {members.map((member) => (
        <StaffMembersListItem
          key={member.id}
          onMemberClick={onMemberClick}
          member={member}
          avatarColor={getNextListColor()}
        />
      ))}
    </div>
  );
};
