import { gql } from '@apollo/client';

export const FRAGMENT_STAFF_INVITATION_DETAILS_DATA = gql`
  fragment StaffInvitationDetailsData on InvitationToken {
    expiresAt
    inviterName
    restaurantName
    token
  }
`;
