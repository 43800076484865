import { FC, ReactNode } from 'react';
import Head from 'next/head';

const DEFAULT_DESCRIPTION =
  'Shop for restaurant-grade groceries at wholesale prices with no hidden fees';
const DEFAULT_OG_IMAGE_URL =
  'https://d2sk68b5f9pot2.cloudfront.net/deeplinks-share-image/og-image.jpg';

export type PageHeadProps = {
  title: string;
  description?: string | null;
  imageURL?: string | null;
  children?: ReactNode;
};

export const PageHead: FC<PageHeadProps> = ({
  title,
  description,
  imageURL,
  children,
}) => {
  description = description || DEFAULT_DESCRIPTION;
  imageURL = imageURL || DEFAULT_OG_IMAGE_URL;
  title = `${title} - Go Cheetah`;
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={imageURL} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={imageURL} />
      <link rel="icon" href="/favicon.ico" />
      {children}
    </Head>
  );
};
