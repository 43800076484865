import { useDispatch, useSelector } from 'react-redux';
import { getInvitation } from '@/store/selectors';
import { useCallback } from 'react';
import { setInvitation } from '@/store/actions';
import { SessionState } from '@/store/slices/session';

export const useInvitation = () => {
  const dispatch = useDispatch();
  const invitation = useSelector(getInvitation);

  const setInvitationState = useCallback(
    (invitation: SessionState['invitation']) => {
      if (invitation) {
        const expired = new Date(invitation.expiresAt * 1000) < new Date();
        dispatch(setInvitation({ ...invitation, expired }));
        return;
      }
      dispatch(setInvitation(invitation));
    },
    [dispatch],
  );
  const resetInvitationState = useCallback(() => {
    dispatch(setInvitation(null));
  }, [dispatch]);

  return {
    invitation,
    setInvitationState,
    resetInvitationState,
  };
};
