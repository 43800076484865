import styles from './StaffEmpty.module.scss';
import { BodyText, HeaderText } from '@/components/Text';
import { Button } from '@/components/Button';
import { FC } from 'react';
import { StaffIcon } from '@/components/icons';
import clsx from 'clsx';

export type StaffEmptyProps = {
  onInviteClick?: () => void;
};

export const StaffEmpty: FC<StaffEmptyProps> = ({ onInviteClick }) => {
  return (
    <div className={styles.empty}>
      <div className={clsx(styles.icon, 'margin-bottom-l')}>
        <StaffIcon size="unset" stroke="none" />
      </div>
      <HeaderText bold className="nospace margin-bottom-l">
        Invite staff to join your team
      </HeaderText>
      <BodyText color="BLACK_50" className="margin-bottom-xxl">
        Your staff will appear here as soon as they accept your invite
      </BodyText>
      <Button variant="secondary" size="large" wide onClick={onInviteClick}>
        Add members
      </Button>
    </div>
  );
};
