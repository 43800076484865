import { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  StaffMemberListItems,
  StaffMemberListItemsVariables,
} from '@/api/__generated__/QUERY_STAFF_MEMBER_LIST_ITEMS.codegen';
import { QUERY_STAFF_MEMBER_LIST_ITEMS } from '@/api/QUERY_STAFF_MEMBER_LIST_ITEMS';
import { useCurrentRestaurantId } from '@/store/useCurrentRestaurantId';
import { QUERY_STAFF_MEMBER_DETAILS } from '@/api/QUERY_STAFF_MEMBER_DETAILS';
import {
  StaffMemberDetails,
  StaffMemberDetailsVariables,
} from '@/api/__generated__/QUERY_STAFF_MEMBER_DETAILS.codegen';
import {
  MakeStaffMemberAsAccountOwner,
  MakeStaffMemberAsAccountOwnerVariables,
} from '@/api/__generated__/MUTATION_MAKE_STAFF_MEMBER_AS_ACCOUNT_OWNER.codegen';
import { MUTATION_MAKE_STAFF_MEMBER_AS_ACCOUNT_OWNER } from '@/api/MUTATION_MAKE_STAFF_MEMBER_AS_ACCOUNT_OWNER';
import { MUTATION_REMOVE_STAFF_MEMBER } from '@/api/MUTATION_REMOVE_STAFF_MEMBER';
import {
  RemoveStaffMember,
  RemoveStaffMemberVariables,
} from '@/api/__generated__/MUTATION_REMOVE_STAFF_MEMBER.codegen';
import { useMyAccount } from '@/api/rest/fetchAccount';
import {
  getStaffMembers,
  getUpdateStaffMemberOptions,
  updateStaffMemberListItemsCacheWithAdmin,
  updateStaffMemberListItemsCacheWithRemovedMember,
} from '@/api/staff/utils';

export type StaffMember = {
  name: string;
  isOwner: boolean;
  id: number;
};

export const useSetStaffMemberAsOwner = () => {
  const restaurantId = useCurrentRestaurantId();
  const [mutate] = useMutation<
    MakeStaffMemberAsAccountOwner,
    MakeStaffMemberAsAccountOwnerVariables
  >(MUTATION_MAKE_STAFF_MEMBER_AS_ACCOUNT_OWNER);

  return {
    setStaffMemberAsOwner: useCallback(
      (userId: number) =>
        mutate(
          getUpdateStaffMemberOptions(
            restaurantId,
            userId,
            updateStaffMemberListItemsCacheWithAdmin,
          ),
        ),
      [mutate, restaurantId],
    ),
  };
};

export const useRemoveStaffMember = () => {
  const restaurantId = useCurrentRestaurantId();
  const [mutate] = useMutation<RemoveStaffMember, RemoveStaffMemberVariables>(
    MUTATION_REMOVE_STAFF_MEMBER,
  );
  const removeStaffMember = useCallback(
    (userId: number) =>
      mutate(
        getUpdateStaffMemberOptions(
          restaurantId,
          userId,
          updateStaffMemberListItemsCacheWithRemovedMember,
        ),
      ),
    [mutate, restaurantId],
  );

  return {
    removeStaffMember,
  };
};

export const getMember = (data?: StaffMemberDetails) => {
  const user = data?.restaurant.users.edges?.[0]?.node;
  const isAdmin = data?.restaurant.users.edges?.[0]?.isAdmin;
  return (
    user && {
      name: user.name,
      isOwner: Boolean(isAdmin),
      id: user.id,
    }
  );
};

export const useStaffMember = (id: number) => {
  const restaurantId = useCurrentRestaurantId();
  const { data, loading, error } = useQuery<
    StaffMemberDetails,
    StaffMemberDetailsVariables
  >(QUERY_STAFF_MEMBER_DETAILS, {
    variables: {
      ids: [id],
      restaurantId,
    },
  });
  const { setStaffMemberAsOwner } = useSetStaffMemberAsOwner();
  const { removeStaffMember } = useRemoveStaffMember();
  const member = useMemo<StaffMember | undefined>(
    () => getMember(data),
    [data],
  );

  return {
    member,
    setStaffMemberAsOwner,
    removeStaffMember,
    loading,
    error,
  };
};

export const useStaff = () => {
  const { data: account } = useMyAccount();
  const restaurantId = useCurrentRestaurantId();
  const { data, loading, error } = useQuery<
    StaffMemberListItems,
    StaffMemberListItemsVariables
  >(QUERY_STAFF_MEMBER_LIST_ITEMS, {
    variables: {
      restaurantId,
    },
  });

  const members = useMemo(
    () => getStaffMembers(data, account),
    [account, data],
  );

  return {
    members,
    loading,
    error,
  };
};
