import { gql } from '@apollo/client';
import { FRAGMENT_STAFF_INVITATION_DETAILS_DATA } from '@/api/fragments/staff/invitationDetails';

export const QUERY_STAFF_INVITATION_DETAILS = gql`
  ${FRAGMENT_STAFF_INVITATION_DETAILS_DATA}
  query StaffInvitationDetails($token: String!) {
    restaurantInvitationToken(token: $token) {
      __typename
      ...StaffInvitationDetailsData
    }
  }
`;
