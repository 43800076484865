import { forwardRef, ForwardRefRenderFunction } from 'react';
import { Appear, Confirm, ConfirmProps } from '@/components/Appear';
import { StaffMember } from '@/api/staff';

export type EditMemberProps = {
  member: StaffMember;
  onMakeOwnerClick: () => Promise<void>;
  onCancelMakeOwnerClick?: () => void;
};

export type EditMemberConfirmViewProps = EditMemberProps & ConfirmProps;

export const EditMemberDialogView: ForwardRefRenderFunction<
  Appear,
  EditMemberConfirmViewProps
> = ({ onMakeOwnerClick, onCancelMakeOwnerClick, member, ...props }, ref) => {
  return (
    <Confirm
      {...props}
      primary={{
        content: 'Yes',
        handler: onMakeOwnerClick,
      }}
      secondary={{
        content: 'No',
        handler: onCancelMakeOwnerClick,
      }}
      title={`Make ${member?.name} account owner?`}
      body={`${member?.name} will have the option to add fellow members and manage payment methods.`}
      ref={ref}
      role="Edit Staff Member"
    >
      Edit Staff Member
    </Confirm>
  );
};

export const EditMemberDialog = forwardRef(EditMemberDialogView);
