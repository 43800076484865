import { gql } from '@apollo/client';
import { FRAGMENT_STAFF_MEMBER_LIST_ITEM_DATA } from '@/api/fragments/staff/listItem';

export const QUERY_STAFF_MEMBER_LIST_ITEMS = gql`
  ${FRAGMENT_STAFF_MEMBER_LIST_ITEM_DATA}
  query StaffMemberListItems($restaurantId: Int!) {
    restaurant(id: $restaurantId) {
      __typename
      id
      users {
        edges {
          isAdmin
          node {
            ...StaffMemberListItemData
          }
        }
      }
    }
  }
`;
