import { useEffect, useCallback, FC } from 'react';
import { useRouter } from 'next/router';
import { useInvitationLink } from '@/api/staff';
import { useInvitation } from '@/store/useInvitation';
import { StaffAcceptInviteDialog } from '@/containers/Staff/StaffAcceptInviteDialog';
import { useIsSignedIn } from '@/api/rest/signIn';
import { showErrorToast } from '../../lib/toast';
import { EventAction, useStaffAnalytics } from '@/api/utils';

export const deleteTokenAndReplaceState = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const url = new URL('/', window.location.origin);
  searchParams.delete('invitation_token');
  url.search = searchParams.toString();
  window.history.replaceState(null, '', '/');
};

export const useDeleteTokenAndReplaceState = () => {
  const { query } = useRouter();

  useEffect(() => {
    if (query.invitation_token) {
      deleteTokenAndReplaceState();
    }
  }, [query.invitation_token]);
};

export const useInvitationState = () => {
  const { query } = useRouter();
  const { getInvitationInfo, invitationInfo } = useInvitationLink();
  const { setInvitationState } = useInvitation();

  useEffect(() => {
    getInvitationInfo(String(query.invitation_token)).catch((e) => {
      showErrorToast((e as Error).message);
    });
  }, [getInvitationInfo, query.invitation_token]);

  useEffect(() => {
    if (invitationInfo) {
      setInvitationState(invitationInfo);
    }
  }, [invitationInfo, query.invitation_token, setInvitationState]);
};

export const StaffAcceptInviteContainer: FC = () => {
  const { acceptInvitation } = useInvitationLink();
  const isSignedIn = useIsSignedIn();
  const { resetInvitationState, invitation } = useInvitation();
  const { trackStaffInvitationDetailsEvent } = useStaffAnalytics();
  const handleAcceptInvite = useCallback(async () => {
    if (invitation?.token) {
      trackStaffInvitationDetailsEvent(EventAction.ACCEPT_TAPPED, {
        invitation_token: invitation.token,
      });
      return acceptInvitation(invitation.token);
    }
  }, [acceptInvitation, invitation, trackStaffInvitationDetailsEvent]);

  useInvitationState();
  useDeleteTokenAndReplaceState();

  if (!invitation) {
    return null;
  }

  return (
    <StaffAcceptInviteDialog
      onShow={() =>
        trackStaffInvitationDetailsEvent(EventAction.VIEW, {
          is_logged_in: isSignedIn,
          invitation_token: invitation.token,
        })
      }
      expired={invitation.expired}
      isSignedIn={isSignedIn}
      onAccept={handleAcceptInvite}
      onDecline={() => {
        resetInvitationState();
        trackStaffInvitationDetailsEvent(EventAction.DECLINE_TAPPED, {
          invitation_token: invitation.token,
        });
      }}
      restaurantName={invitation.restaurantName}
      invitorName={invitation.inviterName}
      onSignInClick={() =>
        trackStaffInvitationDetailsEvent(EventAction.SIGN_IN_TAPPED, {
          invitation_token: invitation.token,
        })
      }
      onSignUpClick={() =>
        trackStaffInvitationDetailsEvent(EventAction.SIGN_UP_TAPPED, {
          invitation_token: invitation.token,
        })
      }
    />
  );
};
