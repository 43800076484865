import { FC, useCallback, useMemo, useRef } from 'react';
import { useStaffMember } from '@/api/staff';
import { BodyText, HeaderText, SubheaderText } from '@/components/Text';
import { EditMemberDialog } from './EditMemberDialog';
import styles from './StaffMemberContainer.module.scss';
import { Button } from '@/components/Button';
import { Avatar } from '@/components/Avatar';
import { EventAction, getInitials, useStaffAnalytics } from '@/api/utils';
import { useCurrentRestaurant } from '@/api/rest/useCurrentRestaurant';
import { Appear } from '@/components/Appear';
import { RemoveMemberDialog } from '@/containers/Staff/RemoveMemberDialog';
import { showErrorToast, showSuccessToast } from '../../lib/toast';
import { useRouter } from 'next/router';

export const getAsyncCallbackWithToast = <T extends unknown, K = void>(
  callback: (params: T) => K,
  successText: string,
) => {
  return async (params?: T) => {
    if (!params) {
      return;
    }

    try {
      const result = await callback(params);
      showSuccessToast(successText);
      return result;
    } catch (e) {
      showErrorToast((e as Error).message);
    }
  };
};

export type StaffMemberContainerProps = {
  id: number;
};

export const StaffMemberContainer: FC<StaffMemberContainerProps> = ({ id }) => {
  const router = useRouter();
  const { member, setStaffMemberAsOwner, removeStaffMember } =
    useStaffMember(id);
  const { data: restaurant } = useCurrentRestaurant();
  const editMemberAppear = useRef<Appear>(null);
  const removeMemberAppear = useRef<Appear>(null);
  const eventProperties = useMemo(
    () => ({
      staff_member_id: id,
    }),
    [id],
  );
  const {
    trackMakeAccountOwnerDialogEvent,
    trackRemoveStaffMemberDialogEvent,
    trackStaffMemberDetailsEvent,
  } = useStaffAnalytics(eventProperties);
  const handleMakeOwnerClick = async () => {
    trackMakeAccountOwnerDialogEvent(EventAction.OK_TAPPED);
    await getAsyncCallbackWithToast<number>(
      setStaffMemberAsOwner,
      'Account owner changed',
    )(id);
  };
  const handleRemoveMemberClick = async () => {
    trackRemoveStaffMemberDialogEvent(EventAction.OK_TAPPED);
    await getAsyncCallbackWithToast<number>(
      removeStaffMember,
      'Member removed',
    )(id);
    router.push('/staff');
  };
  const showEditMemberDialog = useCallback(() => {
    editMemberAppear.current?.show();
    trackStaffMemberDetailsEvent(EventAction.MAKE_ACCOUNT_OWNER_TAPPED);
  }, [trackStaffMemberDetailsEvent]);
  const showRemoveMemberDialog = useCallback(() => {
    removeMemberAppear.current?.show();
    trackStaffMemberDetailsEvent(EventAction.REMOVE_MEMBER_TAPPED);
  }, [trackStaffMemberDetailsEvent]);
  const { query } = useRouter();

  if (!member) {
    return null;
  }

  return (
    <div>
      <div className={styles.header}>
        <HeaderText bold>User details</HeaderText>
        {!member.isOwner && (
          <div className={styles.actions}>
            <Button variant="tertiary" onClick={showRemoveMemberDialog}>
              Remove member
            </Button>
            <Button variant="secondary" onClick={showEditMemberDialog}>
              Make Account owner
            </Button>
          </div>
        )}
      </div>
      <hr />
      {restaurant && (
        <div className="margin-top-xl">
          <Avatar
            initials={getInitials(member.name)}
            color={query.avatar_color && String(query.avatar_color)}
          />
          <SubheaderText bold className="nospace margin-top-xl">
            {member.name}
          </SubheaderText>
          {member.isOwner && <BodyText color="BLACK">Account Owner</BodyText>}
          <EditMemberDialog
            onShow={() => trackMakeAccountOwnerDialogEvent(EventAction.VIEW)}
            ref={editMemberAppear}
            member={member}
            onMakeOwnerClick={handleMakeOwnerClick}
            onCancelMakeOwnerClick={() =>
              trackMakeAccountOwnerDialogEvent(EventAction.CANCEL_TAPPED)
            }
          />
          <RemoveMemberDialog
            onShow={() => trackRemoveStaffMemberDialogEvent(EventAction.VIEW)}
            member={member}
            onRemoveMemberClick={handleRemoveMemberClick}
            ref={removeMemberAppear}
            onCancelRemoveMemberClick={() =>
              trackRemoveStaffMemberDialogEvent(EventAction.CANCEL_TAPPED)
            }
            restaurantName={restaurant?.restaurant_name}
          />
        </div>
      )}
    </div>
  );
};
