import { gql } from '@apollo/client';

export const MUTATION_ADD_STAFF_MEMBER = gql`
  mutation AddStaffMember($input: GenerateInvitationTokenInput!) {
    restaurantGenerateInvitationToken(input: $input) {
      invitationToken {
        token
      }
    }
  }
`;
