import { FC, HTMLAttributes } from 'react';
import { CaptionText, HeaderText } from '@/components/Text';
import { Button } from '@/components/Button';
import styles from './StaffHeader.module.scss';
import clsx from 'clsx';

export type StaffHeaderProps = {
  name: string;
  restaurantName: string;
  onAddMemberClick?: () => void;
} & HTMLAttributes<HTMLDivElement>;

export const StaffHeader: FC<StaffHeaderProps> = ({
  name,
  restaurantName,
  onAddMemberClick,
  ...props
}) => {
  return (
    <div {...props} className={clsx(styles.header, props.className)}>
      <div>
        <HeaderText bold className="nospace">
          {name}&apos;s Staff
        </HeaderText>
        <CaptionText color="BLACK_50">{restaurantName}</CaptionText>
      </div>
      <Button variant="tertiary" onClick={onAddMemberClick}>
        Add members
      </Button>
    </div>
  );
};
