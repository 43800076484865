import { gql } from '@apollo/client';

export const MUTATION_MAKE_STAFF_MEMBER_AS_ACCOUNT_OWNER = gql`
  mutation MakeStaffMemberAsAccountOwner($input: AssignAdminInput!) {
    restaurantAssignAdmin(input: $input) {
      restaurant {
        id
      }
    }
  }
`;
