import { ForwardRefRenderFunction, forwardRef, useMemo } from 'react';
import { Appear, Confirm, ConfirmProps } from '@/components/Appear';
import { InviteIcon } from '@/components/icons';
import styles from './StaffAcceptInviteDialog.module.scss';
import { BodyText, HeaderText } from '@/components/Text';
import Link from 'next/link';
import noop from 'lodash/noop';

export type StaffAcceptInviteDialogViewProps = {
  restaurantName: string;
  invitorName: string;
  onAccept: () => Promise<any>;
  onDecline: () => void;
  isSignedIn: boolean;
  onSignInClick?: () => void;
  onSignUpClick?: () => void;
  expired?: boolean;
} & ConfirmProps;

export const getSignedInActions = (
  onAccept: StaffAcceptInviteDialogViewProps['onAccept'],
  onDecline: StaffAcceptInviteDialogViewProps['onDecline'],
) => [
  {
    content: 'Accept invitation',
    handler: onAccept,
  },
  {
    content: 'Decline invitation',
    handler: onDecline,
  },
];

export const getSignedOutAction = (
  href: string,
  text: string,
  onClick?: () => void,
) => ({
  content: (
    <Link className={styles.link} href={href} onClick={onClick}>
      {text}
    </Link>
  ),
  handler: noop,
});

export const getSignedOutActions = (
  onSignInClick?: () => void,
  onSignUpClick?: () => void,
) => [
  getSignedOutAction('/signup', 'Sign up', onSignUpClick),
  getSignedOutAction('/signin', 'I already have a user', onSignInClick),
];

export const useStaffAcceptActions = ({
  isSignedIn,
  onAccept,
  onDecline,
  onSignInClick,
  onSignUpClick,
  expired,
}: {
  onAccept: StaffAcceptInviteDialogViewProps['onAccept'];
  onDecline: StaffAcceptInviteDialogViewProps['onDecline'];
  isSignedIn: boolean;
  onSignUpClick?: () => void;
  onSignInClick?: () => void;
  expired?: boolean;
}) => {
  return useMemo(() => {
    if (expired) {
      return [
        {
          content: 'Ok',
        },
      ];
    }
    if (isSignedIn) {
      return getSignedInActions(onAccept, onDecline);
    }

    return getSignedOutActions(onSignInClick, onSignUpClick);
  }, [expired, isSignedIn, onAccept, onDecline, onSignInClick, onSignUpClick]);
};

export const StaffAcceptInviteDialogView: ForwardRefRenderFunction<
  Appear,
  StaffAcceptInviteDialogViewProps
> = (
  {
    isSignedIn,
    restaurantName,
    invitorName,
    onAccept,
    onDecline,
    onSignInClick,
    onSignUpClick,
    expired,
    ...props
  },
  ref,
) => {
  const [primary, secondary] = useStaffAcceptActions({
    onAccept,
    onDecline,
    isSignedIn,
    onSignInClick,
    onSignUpClick,
    expired,
  });

  return (
    <Confirm
      {...props}
      defaultVisible
      ref={ref}
      title="&nbsp;"
      body={
        <div className={styles.body}>
          <div className={styles['icon-wrapper']}>
            <InviteIcon size="unset" className={styles.icon} />
          </div>
          <HeaderText bold>
            {expired
              ? 'The invitation link has expired'
              : `You are invited to ${restaurantName}`}
          </HeaderText>
          <BodyText color="BLACK_50">
            {!expired
              ? `${invitorName} would like to add you as a member of this restaurant.`
              : 'Please ask the sender to send a new invitation link.'}
            {!expired &&
              !isSignedIn &&
              `You will need a cheetah account. setup takes less than a minute.`}
          </BodyText>
        </div>
      }
      primary={primary}
      secondary={secondary}
    />
  );
};

export const StaffAcceptInviteDialog = forwardRef(StaffAcceptInviteDialogView);
