import { gql } from '@apollo/client';

export const MUTATION_REMOVE_STAFF_MEMBER = gql`
  mutation RemoveStaffMember($input: DeleteUserInput!) {
    restaurantDeleteUser(input: $input) {
      restaurant {
        id
      }
    }
  }
`;
