import { forwardRef, ForwardRefRenderFunction } from 'react';
import { Appear, Confirm, ConfirmProps } from '@/components/Appear';
import { StaffMember } from '@/api/staff';

export type RemoveMemberProps = {
  member: StaffMember;
  onRemoveMemberClick: () => Promise<void>;
  onCancelRemoveMemberClick?: () => void;
  restaurantName: string;
};

export type RemoveMemberDialogViewProps = RemoveMemberProps & ConfirmProps;

export const RemoveMemberDialogView: ForwardRefRenderFunction<
  Appear,
  RemoveMemberDialogViewProps
> = (
  {
    onRemoveMemberClick,
    onCancelRemoveMemberClick,
    member,
    restaurantName,
    ...props
  },
  ref,
) => {
  return (
    <Confirm
      {...props}
      primary={{
        content: 'Yes',
        handler: onRemoveMemberClick,
      }}
      secondary={{
        content: 'No',
        handler: onCancelRemoveMemberClick,
      }}
      title="Remove staff member"
      body={`${member.name} will be removed from your staff. He will no longer has access to ${restaurantName}. Are you sure you would like to proceed?`}
      ref={ref}
      role="Remove Staff Member"
    >
      Edit Staff Member
    </Confirm>
  );
};

export const RemoveMemberDialog = forwardRef(RemoveMemberDialogView);
