import {
  forwardRef,
  useEffect,
  useRef,
  useState,
  MutableRefObject,
  useMemo,
  RefObject,
  Dispatch,
  SetStateAction,
  useCallback,
  ForwardRefRenderFunction,
} from 'react';
import { Appear, Confirm, ConfirmProps } from '@/components/Appear';
import { TextInput } from '@/components/TextInput';
import delay from 'lodash/delay';

export type AddMemberDialogViewProps = {
  restaurantName: string;
  onSendInviteClick: () => Promise<any>;
  invitationLink?: string;
  onCancelClick?: () => void;
  onScreenChange?: (screen: AddMemberScreen) => void;
  onShareLink?: () => void;
} & ConfirmProps;

type AddMemberScreen = 'initial' | 'link';

export const BUTTON_COPY_TEXT_EFFECT_DELAY = 1000;

const useInitialMemberState = ({
  restaurantName,
  onSendInviteClick,
  onCancelClick,
}: {
  restaurantName: string;
  onSendInviteClick?: () => Promise<void>;
  onCancelClick?: () => void;
}) => {
  const [state, setState] = useState<ConfirmProps | null>();
  useEffect(() => {
    setState({
      title: 'Add New Members',
      body: `Add new members to ${restaurantName}. This link will expire after 1 day. Added members require approval once invitation is used.`,
      primary: {
        content: 'Send invite',
        handler: onSendInviteClick,
        hideOnSuccess: false,
      },
      secondary: {
        content: 'Cancel',
        handler: onCancelClick,
      },
    });
  }, [onSendInviteClick, restaurantName]);

  return {
    state,
  };
};

export const handleCopyLinkClick = async ({
  invitationLink,
  linkInputRef,
  state,
  setState,
  copyTimer,
}: {
  invitationLink: string;
  linkInputRef: RefObject<HTMLInputElement>;
  state: ConfirmProps;
  setState: Dispatch<SetStateAction<ConfirmProps | null | undefined>>;
  copyTimer: MutableRefObject<number | undefined>;
}) => {
  await navigator.clipboard.writeText(invitationLink);
  linkInputRef.current?.focus();
  linkInputRef.current?.select();

  setState({
    ...state,
    primary: {
      ...state.primary,
      content: 'Copied',
    },
  });

  copyTimer.current = delay(
    () => setState(state),
    BUTTON_COPY_TEXT_EFFECT_DELAY,
  );
};

export const useLinkMemberState = ({
  invitationLink,
  copyTimer,
  onShareLink,
}: {
  invitationLink: string;
  copyTimer: MutableRefObject<number | undefined>;
  onShareLink?: () => void;
}) => {
  const linkInputRef = useRef<HTMLInputElement>(null);
  const [state, setState] = useState<ConfirmProps | null>();

  useEffect(() => {
    setState(() => {
      const state = {
        title: 'Link to share',
        onHide: () => {
          clearTimeout(copyTimer.current);
          setState(state);
        },
        body: (
          <TextInput
            defaultValue={invitationLink}
            ref={linkInputRef}
            autoFocus
            onFocus={(e) => e.currentTarget.select()}
          />
        ),
        primary: {
          content: 'Share link',
          handler: () => {
            onShareLink?.();
            handleCopyLinkClick({
              invitationLink,
              linkInputRef,
              state,
              setState,
              copyTimer,
            });
          },
          hideOnSuccess: false,
        },
      };

      return state;
    });
  }, [copyTimer, invitationLink, linkInputRef, onShareLink]);

  return {
    state,
  };
};

const useScreenState = (
  invitationLink?: string,
  onScreenChange?: (screen: AddMemberScreen) => void,
) => {
  const copyTimer = useRef<number>();
  const [screen, setScreen] = useState<AddMemberScreen>('initial');

  const resetState = useCallback(() => {
    setScreen('initial');
    clearTimeout(copyTimer.current);
  }, []);

  useEffect(() => {
    invitationLink && setScreen('link');
  }, [invitationLink]);

  useEffect(() => {
    onScreenChange?.(screen);
  }, [onScreenChange, screen]);

  return {
    screen,
    copyTimer,
    resetState,
  };
};

export const useAddMemberState = ({
  invitationLink,
  onScreenChange,
  onShareLink,
  ...rest
}: {
  restaurantName: string;
  invitationLink?: string;
  onSendInviteClick?: () => Promise<void>;
  onCancelClick?: () => void;
  onScreenChange?: (screen: AddMemberScreen) => void;
  onShareLink?: () => void;
}) => {
  const { resetState, screen, copyTimer } = useScreenState(
    invitationLink,
    onScreenChange,
  );
  const { state: initialMemberState } = useInitialMemberState(rest);
  const { state: linkMemberState } = useLinkMemberState({
    invitationLink: invitationLink!,
    copyTimer,
    onShareLink,
  });
  const stateByScreen = useMemo(() => {
    const map = new Map<AddMemberScreen, ConfirmProps | null | undefined>();
    map.set('initial', initialMemberState);
    map.set('link', linkMemberState);
    return map;
  }, [initialMemberState, linkMemberState]);

  return {
    state: {
      ...stateByScreen.get(screen),
      onHide: resetState,
    },
  };
};

export const AddMemberDialogView: ForwardRefRenderFunction<
  Appear,
  AddMemberDialogViewProps
> = (
  {
    restaurantName,
    invitationLink,
    onSendInviteClick,
    onCancelClick,
    onScreenChange,
    onShareLink,
    ...props
  },
  ref,
) => {
  const { state } = useAddMemberState({
    restaurantName,
    invitationLink,
    onSendInviteClick,
    onCancelClick,
    onScreenChange,
    onShareLink,
  });

  return <Confirm {...props} {...state} ref={ref} role="Add Staff Member" />;
};

export const AddMemberDialog = forwardRef(AddMemberDialogView);
