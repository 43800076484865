import { FC, useMemo } from 'react';
import styles from './StaffMembersListItem.module.scss';
import { BodyText, FootnoteText } from '@/components/Text';
import { Badge } from '@/components/Badge';
import { CaretRightIcon } from '@/components/icons';
import clsx from 'clsx';
import { getInitials } from '@/api/utils';
import { Avatar } from '@/components/Avatar';
import { StaffMember } from '@/api/staff';
import Link from 'next/link';

export type StaffMembersListItemProps = {
  member: StaffMember;
  avatarColor: string;
  onMemberClick?: (member: StaffMember) => void;
};

export const StaffMembersListItem: FC<StaffMembersListItemProps> = ({
  member,
  onMemberClick,
  avatarColor,
}) => {
  const initials = useMemo(() => getInitials(member.name), [member.name]);
  return (
    <Link
      className={styles.item}
      href={`/staff/${member.id}?avatar_color=${avatarColor}`}
      onClick={() => onMemberClick?.(member)}
    >
      <div className={clsx(styles.member, 'nowrap')}>
        <Avatar initials={initials} color={avatarColor} />
        <BodyText bold>{member.name}</BodyText>
      </div>
      <div className={styles.badge}>
        {member.isOwner && (
          <Badge>
            <FootnoteText bold className="nowrap">
              Account Owner
            </FootnoteText>
          </Badge>
        )}
      </div>
      <CaretRightIcon color="BLACK" size="medium" />
    </Link>
  );
};
