import styles from './Avatar.module.scss';
import { CaptionText } from '@/components/Text';
import { FC } from 'react';
import { getColorValue } from '@/components/ColorValues';

export type AvatarProps = {
  initials: string;
  color?: string;
};

export const Avatar: FC<AvatarProps> = ({
  initials,
  color = getColorValue('LIST_1'),
}) => (
  <CaptionText
    bold
    color="WHITE"
    className={styles.avatar}
    style={{ background: color }}
  >
    {initials}
  </CaptionText>
);
