import { gql } from '@apollo/client';

export const MUTATION_ACCEPT_INVITATION_TOKEN = gql`
  mutation AcceptInvitationToken($input: JoinByInvitationTokenInput!) {
    restaurantJoinByInvitationToken(input: $input) {
      restaurant {
        id
      }
    }
  }
`;
